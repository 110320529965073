import './App.css';
import { Route, Routes } from 'react-router-dom';
import Waitlist from './pages/waitlist/Waitlist';

function App() {
  return (
    <Routes>
      <Route path='*' element={<Waitlist />}/>
      <Route path='wait-list' element={<Waitlist/>}/>
    </Routes>
  );
}

export default App;
