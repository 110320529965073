import React, { useRef, useState } from 'react'
import Swal from "sweetalert2"
import { SpinningCircles } from 'react-loading-icons'
import {PiCaretDownBold} from 'react-icons/pi'
import FooterDashboard from '../../components/footer-dashboard/FooterDashboard';
import Logo from "../../assets/images/logo-l.png"
// import BannerImg from "../../assets/images/hero-img.png";
import AboutusImg from "../../assets/images/who-we-are.png";
import Prof1 from "../../assets/images/musician.png";
import Prof2 from "../../assets/images/curator.png";
import heroImg from "../../assets/images/wlh-img.png"
import './waitlist.css'

function Waitlist() {
    const options = ["Musician","Influencer","Playlist Curator","OAP","DJ","Blogger","Show Promoter","Others"]
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState("")
    const [openOption, setOpenOption] = useState(false);
    const ref = useRef(null);
    const refFooter = useRef(null);
    const refABout = useRef(null);
    const [name, set_name] = useState("");
    const [email, set_email] = useState("");
    const scrollToElement = () => ref.current?.scrollIntoView({ behavior: 'smooth' });
    const scrollContact = () => refFooter.current?.scrollIntoView({behavior: 'smooth'});
    const scrollAbout = () => refABout.current?.scrollIntoView({behavior: 'smooth'});

    const isEmpty = () => {
        let check = option && name && email;
        if(!check){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All field must be filled'
            })
            setLoading(false);
        }
        return check;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!isEmpty()) return;
        try {
            const res = await fetch(" https://app.newbanger.com/api/join-waitlist", {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept':'application/json'},
                body: JSON.stringify({user_type: option,name,email})
            })
            const data = await res.json();
            if (res.ok) {
                Swal.fire(
                    {
                        position: 'top-end',
                        icon: 'success',
                        title: data.message,
                        showConfirmButton: true,
                        confirmButtonColor: "#FF614D",
                        timer: 10000
                    }
                )

                if (window.fbq) {
                    window.fbq("trackCustom", "JoinButtonSubmitSuccess", {});
                }
            } else {
                throw Error("Unable to save response please check and try again")
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: e.message
            })
            if (window.fbq) {
                window.fbq("trackCustom", "JoinButtonSubmitError", {});
            }
        }
        set_name(""); set_email(""); setOption(""); setLoading(false);
    }
  return (
    <div className='waitlist-component'>
        <div className="nav-container">
            <nav className='waitlist-navbar'>
                <div className="logo"><img src={Logo} alt="" /></div>
                <ul className='navigation-list'>
                    <li className='nav-item'>Home</li>
                    <li className='nav-item' onClick={scrollAbout}>About Us</li>
                    <li className='nav-item' onClick={scrollContact}>Contact Us</li>
                </ul>
                <div className="waitlist-btn">
                    <button id='join-button-nav' onClick={scrollToElement}>
                                Join Our Waitlist
                    </button>
                </div>
            </nav>
        </div>
        <header className='waitlist-header'>
            <div className="header-background-container">
                <div className="header-content-container">
                    <div className="header-content-hero-img">
                        <div className="header-content">
                            <h3>We Connect Musicians with Music Professionals</h3>
                            <p>Newbanger is helping to reduce the costs associated with music promotion, making it more affordable and making the process more transparent</p>
                            <div className="waitlist-input-btn">
                                {/* <input type="text" placeholder='Email address'/> */}
                                <button id='join-button-hero'
                                onClick={scrollToElement}>Join Our Waitlist</button>
                            </div>
                                
                        </div>
                        <div className="header-img">
                            <div className="header-img-container">
                                {/* <div className="bg-arc"></div> */}
                                <div className="img-container">
                                    <img src={heroImg} alt="hero background" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section className="connect-professionals">
            <div className="connect-professionals-container">
                <div className="who-it-is-built-for">
                    <h3>Who we Built Newbanger For</h3>
                    <p> We have built an innovative platform that connects artists who want to 
                        promote their music with the best music blogs, curators, radios, 
                        and labels seeking emerging talents.
                    </p>
                </div>
                <div className="professional-list">
                    <div className="professional-img-title">
                        <img src={Prof1} alt="" />
                        <h4>For Musicians</h4>
                        <p>
                            Digitize your campaign, make it available to a wide range of 
                            (or selected) brand promoters and social media influencers at 
                            a go. Drive your marketing goal effectively and monitor it.
                        </p>
                        <button id='join-button-who-we-built1' 
                        onClick={scrollToElement}>Join Waitlist</button>
                    </div>
                    <div className="professional-img-title">
                        <img src={Prof2} alt="" />
                        <h4>For Music Curator/pro</h4>
                        <p>
                            Join NewBanger's vibrant community of 
                            music curators and professionals to unlock endless possibilities for 
                            promoting exceptional music and boosting your revenue.
                        </p>
                        <button id='join-button-who-we-built2'
                        onClick={scrollToElement}>Join Waitlist</button>
                    </div>
                </div>
            </div>
        </section>
        <section className='who-we-are' ref={refABout}>
            <div className="who-we-are-container">
                <div className="who-we-are-img">
                    <img src={AboutusImg} alt="" />
                </div>
                <div className="who-we-are-content">
                    <h3>Who We are</h3>
                    <div className='underline'></div>
                    <p>
                        We're Revolutionizing music promotion by empowering musicians with a Do it yourself platform that 
                        allows them to promote their music by connecting them directly with music curators and professionals 
                        cutting off middle men which brings about reduction in cost, transparency and desired results .
                    </p>
                    <p>
                        With Newbanger, you can save money on traditional music promotion methods 
                        while focusing on building meaningful relationships with your audience. 
                    </p>
                    <p>
                        Our technology enables you to effortlessly target your intended audience and track 
                        your success in real-time, allowing you to fine-tune your strategy and maximize the effectiveness of your efforts. 
                    </p>
                    <p>

                        We give you the tools you need to expand your popularity and take your music career to the next level, unlike other 
                        music promotion platforms. Join our successful artist community and see the difference Newbanger can make in your 
                        music promotion efforts.
                    </p>
                    <button id='join-button-who-we-are'
                     onClick={scrollToElement}>Join Our Waitlist</button>
                </div>
                
            </div>
            
        </section>
        <section className="waitlist-content-form-field">
            <div className="waitlist-content">
                <h3>Newbanger Is Launching Soon!!!</h3>
            </div>
            <div className="waitlist-form-field-container" ref={ref}>
                <h3>Join Our Waitlist</h3>
                <p>We have built an innovative platform that connects artists who want 
                    to promote their music with the best music blogs, curators, radios, 
                    and labels seeking emerging talents.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <label htmlFor="name">What is your name ? *</label><br />
                        <input type="text" value={name} onChange={(e) => set_name(e.target.value)} placeholder='Wale Akintade'/>
                    </div>
                    <div className="form-field"><br />
                        <label htmlFor="name">What is your email ? *</label><br />
                        <input type="email" value={email} onChange={(e) => set_email(e.target.value)} placeholder='hello@newbanger.com'/>
                    </div>
                    <div className="form-field"><br />
                        <label htmlFor="name">What best describe you ? *</label><br />
                          <div className="select-container" onClick={() => setOpenOption(!openOption)}>
                              <div className="selected-option">
                                  <p className={option?'active':''}>{option || "Musician,Blogger..."}</p>
                                  <span><PiCaretDownBold /></span>
                              </div>
                              <ul className={openOption?"select-options":"select-options open-select"}>
                                  <li onClick={() => setOption(options[0])}>Musician</li>
                                  <li onClick={() => setOption(options[1])}>Influencer</li>
                                  <li onClick={() => setOption(options[2])}>Playlist Curator</li>
                                  <li onClick={() => setOption(options[3])}>OAP</li>
                                  <li onClick={() => setOption(options[4])}>DJ</li>
                                  <li onClick={() => setOption(options[5])}>Blogger</li>
                                  <li onClick={() => setOption(options[6])}>Show Promoter</li>
                                  <li onClick={() => setOption(options[7])}>Others</li>
                              </ul>
                          </div>
                    </div>
                    <div className="form-field"><br />
                        {loading?
                            (<button id='join-button-submit'>
                                <SpinningCircles strokeWidth={.1}/>
                            </button>):
                            <input type="submit" id='join-button-submit' value='Join the Waitlist'/>
                        }
                    </div>
                </form>
            </div>
        </section>
        <FooterDashboard refFooter = {refFooter}/>
    </div>
  )
}

export default Waitlist