import React from 'react'
import {FiInstagram} from "react-icons/fi";
import {CiTwitter,CiFacebook} from "react-icons/ci";
import {BiPhone} from "react-icons/bi";
import {BsEnvelope} from "react-icons/bs";
import {PiYoutubeLogoLight,PiDiscordLogoBold,PiTiktokLogoBold, PiSpotifyLogo} from "react-icons/pi";
import logo from "../../assets/images/logo.png";
import "./footer-dashboard.css";
import { Link } from 'react-router-dom';

function FooterDashboard({refFooter}) {
  return (
    <footer className='dashboard-footer'>
        <div className="footer-container">
            <div className="footer-logo">
                <img src={logo} alt="Newbanger logo" />
            </div>
            <ul className="explore-list">
                <h3 ref={refFooter}>Contact us</h3>
                <li>
                    <span className='icon'><BsEnvelope/></span>
                    <span>info@newbanger.com</span>
                </li>
                <li>
                    
                        <span className='icon'><Link to="https://wa.link/32xqru" className='link'><BiPhone/></Link></span>
                        <span><Link to="https://wa.link/32xqru" className='link'>+2348069698488</Link></span>
                    
                    
                </li>
            </ul>
            <ul className="explore-list">
                <h3>Follow us and stay connected</h3>
                <li>
                    <div className="social-media-icons">
                        <span><Link className='link' target='_blank' to='https://www.facebook.com/newbangercom'><CiFacebook/></Link></span>
                        <span><Link className='link' target='_blank' to='https://www.instagram.com/newbangercom'><FiInstagram/></Link></span>
                        <span><Link className='link' target='_blank' to='https://twitter.com/newbangercom'><CiTwitter/></Link></span>
                        <span><Link className='link' target='_blank' to='https://www.youtube.com/c/NewbangerTV'><PiYoutubeLogoLight/></Link></span>
                        <span><Link className='link' target='_blank' to='https://www.discord.gg/r329krta6Q'><PiDiscordLogoBold/></Link></span>
                        <span><Link className='link' target='_blank' to='https://www.tiktok.com/@newbangercom'><PiTiktokLogoBold/></Link></span>
                        <span><Link className='link' target='_blank' to='https://www.spotify.link/bW1fF83ACDb'><PiSpotifyLogo/></Link></span>
                    </div>
                </li>
            </ul>
        </div>
        <div className="footer-note">
            <p>Copyright &copy; 2023 Newbanger Digital Limited</p>
        </div>
    </footer>
  )
}

export default FooterDashboard